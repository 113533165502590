@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

* > ::-webkit-scrollbar {
  width: 12px;
  border-radius: 100%;
  overflow: hidden;
}

/* Track */
* > ::-webkit-scrollbar-track {
  background: #fff0;
}

/* Handle */
* > ::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 20px;
  overflow: hidden;
  border: 2px solid #fff;
}

/* Handle on hover */
* > ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.img-text {
  text-transform: uppercase;
  font-size: 80px;
  background: url('https://images.unsplash.com/photo-1507880572231-f85401ce76e6?ixlib=rb-1.2.1&q=85&fm=jpg&crop=entropy&cs=srgb&ixid=eyJhcHBfaWQiOjE0NTg5fQ');
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInFromNone 1s ease-out;
}

.button-shadow {
  box-shadow: 0 3px 0 0 #000;
}

.custom-range-slider::-webkit-slider-runnable-track {
  -webkit-appearance: none;
}

.react-swipe-button,
.react-swipe-button * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  not supported by any browser */
}

.swipe-shadow {
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
}

@keyframes fadeInFromNone {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

ul {
  list-style-type: initial;
  padding-left: 20px;
}

.wrapper {
  width: 600px;
  margin: 0 auto;
}

.button-gradient {
  background: linear-gradient(180deg, #fbfbfb 0%, #f2f2f2 100%);
}

.accordion-item {
  overflow: hidden;
  transition: all 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}

.accordion-item.collapsed {
  max-height: 0;
  transition: all 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  color: #666;
  padding: 0.5em 0px;
  padding-right: 0.5em;
  border-bottom: solid 1px #ccc;
  display: flex;
  gap: 15px;
  position: relative;
}

.accordian-icon {
  position: absolute;
  right: 0;
  padding: 6px;
}

.accordian-icon:hover {
  border-radius: 4px;
  background-color: #f0f6ff;
}

.accordion-title:hover,
.accordion-title.open {
  color: black;
}

.accordion-content {
  padding: 1em 0px;
  border-bottom: solid 1.5px #ccc;
}

.bottom-bar {
  box-shadow: 0px 4px 19px rgba(0, 0, 0, 0.1);
}

.kyc-gradient-informated_requested {
  background: conic-gradient(from 180deg at 50% 50%, #b2f3c8 0deg, #5f978d 97.5deg, #b3d36e 191.25deg, #a7f8db 360deg),
    conic-gradient(from 180deg at 49.14% 50%, #b2f3c8 0deg, #907eff 360deg);
  background-size: 100% 100%;
}

.kyc-gradient-failed {
  background: conic-gradient(from 180deg at 50% 50%, #b2f3c8 0deg, #5f978d 97.5deg, #b3d36e 191.25deg, #a7f8db 360deg),
    conic-gradient(from 180deg at 49.14% 50%, #b2f3c8 0deg, #907eff 360deg);
  background-size: 100% 100%;
}

.help-banner {
  background: conic-gradient(from 179deg at 50% 50%, #b2f3c8 0deg, #5f978d 97.5deg, #b3d36e 191.25deg, #a7f8db 360deg),
    conic-gradient(from 180deg at 49.14% 50%, #b2f3c8 0deg, #907eff 360deg);
  background-size: 100% 100%;
}

.beneficiary-seperator-label {
  position: absolute;
  left: -20px;
  top: -12px;
}

.transaction-seperator-label {
  position: absolute;
  left: -60px;
  top: -12px;
  z-index: 10;
}

@media screen and (max-width: 992px) {
  .transaction-seperator-label {
    left: -20px;
    background-color: white;
    padding-right: 10px;
  }
}

input:focus-visible {
  outline: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.lottie-player {
  height: 55px;
}

.Toastify__toast {
  width: 510px !important;
  border-radius: 10px !important;
  padding: 16px 24px !important;
  min-height: 56px !important;
}

@media (max-width: 768px) {
  .Toastify__toast {
    width: 100% !important;
    padding: 12px 16px !important;
    min-height: 48px !important;
    margin: 16px 0px !important;
  }
}

.Toastify__toast--success {
  background-color: #e9f6f2 !important;
  border: 1px solid #21bf86 !important;
}

.Toastify__toast--error {
  background-color: #fce9e9 !important;
  border: 1px solid #d64141 !important;
}

.Toastify__toast--warning {
  background-color: #faf8e7 !important;
  border: 1px solid #eed202 !important;
}

.Toastify__toast-body {
  margin: 0 !important;
  padding: 0 !important;
}

.Toastify__close-button {
  position: relative;
}

input:disabled {
  background-color: white;
}

.custom-scroll-bar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 1px;
}

/* Track */
.custom-scroll-bar::-webkit-scrollbar-track {
  background: #f0f0f0;
}

/* Handle */
.custom-scroll-bar::-webkit-scrollbar-thumb {
  background: #d9d9d9;
}

/* Handle on hover */
.custom-scroll-bar::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.animate-spin {
  animation: spin 2s linear infinite;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 12px solid #2546f5;
}

.toast-animation {
  animation-name: toast-transition;
  animation-duration: 2s;
  transform: translateY(0px);
  opacity: 0;
}

.offline-tranision {
  animation-duration: 1s;
  animation-name: offline-transition;
}

@keyframes offline-transition {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes toast-transition {
  0% {
    transform: translateY(-40px);
    opacity: 0;
  }

  25% {
    transform: translateY(0px);
    opacity: 1;
  }

  75% {
    transform: translateY(0px);
    opacity: 1;
  }

  100% {
    transform: translateY(-40px);
    opacity: 0;
  }
}

.sign-in-animation {
  margin-top: 0px;
  transition: 0.8s;
  transition-timing-function: ease;
  background: linear-gradient(220.02deg, #1932f1 -4.28%, rgba(37, 70, 245, 0) 80%);
}

@media screen and (max-width: 1200px) {
  .sign-in-animation {
    background: linear-gradient(220.02deg, #1932f1 -4.28%, rgba(37, 70, 245, 0) 60%);
  }
  .sidebar-item {
    width: 84px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar-item > span {
    display: none;
  }

  .sidebar-selection-dot {
    display: none;
  }
}

.grey-bg {
  background-color: rgba(0, 0, 0, 0.3);
}

.Document-container {
  margin: 1em 0;
}

.Document-container .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Document-container .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.Document-container .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.Document-container .react-pdf__message {
  padding: 20px;
  color: white;
}

.total-balance-container {
  background: linear-gradient(180deg, #d8d8d8 0%, #0a8458 100%);
}

.total-available-cash-container {
  background: linear-gradient(180deg, #d0d0d0 0%, #2546f5 100%);
}

.box-shadow {
  box-shadow: 0px 3px 9px rgba(60, 66, 87, 0.08), 0px 2px 5px rgba(60, 66, 87, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.08);
}

.lavander_1-box-shadow {
  box-shadow: 1px 2px 10px rgba(197, 220, 255, 0.54);
}

.show-on-hover {
  display: none;
}

.hover-toggle:hover .show-on-hover {
  display: block;
}

.hover-toggle:hover .show-without-hover {
  display: none;
}

.checkbox-container .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 1.5px 1.5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* temp css  */

.min-max-input::-ms-track {
  appearance: none;
  background: transparent;
  border: transparent;
}

.min-max-input::-moz-range-track {
  appearance: none;
  background: transparent;
  border: transparent;
}

.min-max-input:focus::-webkit-slider-runnable-track {
  appearance: none;
  background: transparent;
  border: transparent;
}

.min-max-input::-ms-thumb {
  appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;
}

.min-max-input::-ms-thumb:active {
  cursor: grabbing;
}

.min-max-input::-moz-range-thumb {
  appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;
}

.min-max-input::-moz-range-thumb:active {
  cursor: grabbing;
}

.min-max-input::-webkit-slider-thumb {
  appearance: none;
  pointer-events: all;
  width: 16px;
  height: 16px;
  border-radius: 0px;
  border: 0 none;
  cursor: grab;
  background-color: red;
}

.min-max-input::-webkit-slider-thumb:active {
  cursor: grabbing;
}

.control {
  transform: translate3d(0, -50%, 0);
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .tw-no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .tw-no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

button {
  border-radius: 8px !important;
}

/*TODO: check if tthis can be moved to tailwind*/

.translate-animation-otc-loader {
  animation: OtcLoader 2s infinite linear;
}

@keyframes OtcLoader {
  from {
    width: 0;
  }
  to {
    width: 218px;
  }
}
